@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "Avenir";
  src:
    url("/public/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src:
    url("/public/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src:
    url("/public/fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src:
    url("/public/fonts/FontsFree-Net-AvenirLTStd-Medium.woff2") format("woff2"),
    url("/public/fonts/FontsFree-Net-AvenirLTStd-Medium.woff") format("woff"),
    url("/public/fonts/FontsFree-Net-AvenirLTStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Assistant", sans-serif;
  /* font-family: 'Nabla', cursive !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

progress[value]::-webkit-progress-value {
  background-color: #000000;
  border-radius: 32px;
}

progress {
  height: 56px;
  border-radius: 32px;
}

progress::-webkit-progress-bar {
  background-color: #f2f2f2;
  height: 56px;
  border-radius: 32px;
}

progress::-moz-progress-bar {
  background-color: #f2f2f2;
  height: 56px;
  border-radius: 32px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
}
.toggle-switch input[type="checkbox"] {
  appearance: none;
  opacity: 0;
}

.toggle-switch input[type="checkbox"]:focus + .switch {
  transform: scale(1.1);
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 68px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  transition: all 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 15px;
  top: 10px;
  width: 45px;
  height: 45px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(100%);
  background-color: #ffffff;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #000000;
}

.multi-select * {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 7px;
    width: 25px;
    height: 25px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  progress {
    height: 40px;
  }

  progress::-webkit-progress-bar {
    height: 40px;
  }

  progress::-moz-progress-bar {
    height: 40px;
  }
}
