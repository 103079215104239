/* Navbar */
/* body {
  padding-top: 56px;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  padding: 20px;
}

/* Main Container */

.main-container {
  width: 1440px;
  max-width: 100%;

  /* Page Padding */
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .main-container {
    /* Left and Right Padding for bigger screens */
    padding-right: 60px;
    padding-left: 60px;
  }
}

/* Current Account Button, others... */

.transparent-hover-button:hover:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

[type="checkbox"],
[type="radio"] {
  color: black;
}

[type="radio"]:focus {
  outline: black;
  --tw-ring-color: black;
}
